<template>
    <div >
    <div class="row h-100 mt-3">
        <div class="col-4 border-end h-100">
            <h3>Thanks for being an annotater.</h3>
            <p>
                This tool is still in testing and this is the first version, however the shortcuts are listed below:
            </p>

            <div>
                <div class="d-flex mt-2" v-for="keyCode in Object.keys(hotkeys)" :key="keyCode">
                    <div style="height: 30px; width: 30px; border: 1px solid black; background: #c3c3c3; text-align: center; padding-top: 1px; border-radius: 5px;"> 
                        {{ String.fromCharCode(keyCode) }}
                    </div>
                    <div class="ms-2 my-auto">
                        {{ hotkeys[keyCode] }}
                    </div>
                </div>
            </div>

            <div class="m5-4" v-if="selectedViewpoint">
                <!-- <div>Tto annotated for this viewpoint:</div> -->
                
                <!-- <div class="progress">
                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :aria-valuenow="(((selectedViewpoint.totalFrames - frames.length) + framesRemaining) / selectedViewpoint.totalFrames) * 100" aria-valuemin="0" aria-valuemax="100" :style="{ 'width': ((selectedViewpoint.totalFrames - framesRemaining) / selectedViewpoint.totalFrames) * 100 + '%' } "></div>
                </div> -->
            </div>
        </div>
        <div class="col-8" v-if="selectedFrame">
            <div class="d-flex">
                <div class="btn-group">
                    <a href="#" class="btn btn-primary active" aria-current="page" @click="selectedFrameIndex--">-</a>
                    <input v-model="selectedFrameIndex" type="number" class="form-control">
                    <a href="#" class="btn btn-primary" @click="selectedFrameIndex++">+</a>
                </div>
                <div class="ms-4"> Total Frames: {{ selectedViewpoint.totalFrames }} </div>    
            </div>
            <div>Current frame ID: {{selectedFrame.id }}</div>
            <div>
                <image-labeller ref="labeller" @boxes="setBoxes" :image="selectedFrame.url" />
            </div>
        </div>
        <div class="col-8" v-else-if="noMoreFramesToLoad">
            No more frames to annotate, thank you :)
        </div>
        <div class="col-8" v-else-if="loading">
            Loading
        </div>
        <div class="col-8" v-else>
            No frame loaded
        </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { getFrames, upsertFrame } from "@/api";
import ImageLabeller from "@/components/ImageLabeller"
export default {
    data() {
        return {
            framesPage: 0,
            frames: [],
            selectedFrameIndex: 0,
            framesAnnotatedInSession: 0,
            noMoreFramesToLoad: false,
            loading: true,
            labels: [
                // 'UNKNOWN',
                'NO_EVENT',
                // 'READY',
                'CONTACT',
                // 'PUNCH',
                // 'KICK',

                'RED_KICK',
                'BLUE_KICK',
                'BOTH_KICK',
            ],
            hotkeys: {
                // keyCode: 'label'
                // 48: 'UNKNOWN',
                49: 'NO_EVENT',

                50: 'CONTACT',
                // 51: 'KICK',
                // 52: 'PUNCH',

                55: 'RED_KICK',
                56: 'BOTH_KICK',
                57: 'BLUE_KICK',

                187: 'SKIP',
                189: 'PREVIOUS',
            },
            hiddenHotkeys: {
                // Numpad
                // 96: 'UNKNOWN',
                97: 'NO_EVENT',
                98: 'CONTACT',
                // 99: 'KICK',
                // 100: 'PUNCH',

                103: 'RED_KICK',
                104: 'BOTH_KICK',
                105: 'BLUE_KICK',


                107: 'SKIP',
                109: 'PREVIOUS',
            },
            specialHandlers: {
                'SKIP': this.skipFrame,
                'PREVIOUS': this.previousFrame,
            },
        }
    },
    components: {
        ImageLabeller
    },
    async mounted() {
        if (!this.selectedViewpoint) {
            await this.$store.dispatch('getViewpoint', this.$route.params.id);
            this.$store.dispatch('selectViewpoint', this.$route.params.id);
        }

        console.log("Mounted");
        this.loadMoreFrames();
        document.addEventListener('keydown', this.processHotkey);
    },
    destoryed() {

    },
    methods: {
        async setBoxes(boxes){  
            this.selectedFrame.boxes = boxes;
        },
        async skipFrame() {
            this.selectedFrameIndex++;
            // this.$refs.labeller.boxes = [];
        },
        async previousFrame() {
            this.selectedFrameIndex--;
            // this.$refs.labeller.boxes = [];
        },
        async processHotkey(e) {
            let keyCode = e.keyCode || e.which || e.code;

            if (this.getFramesRemaining() < 10) {
                console.log("Frames remaining is low, loading more frames")
                this.loadMoreFrames();
            }

            if (Object.keys(this.hotkeys).find(x => x == keyCode)) {
                console.log("Found label!");
                e.preventDefault();

                let label = this.hotkeys[keyCode];
                if (this.specialHandlers[label]) {
                    console.log("Got special handler");
                    return this.specialHandlers[label]();
                }
                
                if (!this.selectedFrame) return console.log("No frame");
                this.labelSelectedFrame(label);


                return true;
            }

            if (Object.keys(this.hiddenHotkeys).find(x => x == keyCode)) {
                console.log("Found label!");
                e.preventDefault();

                let label = this.hiddenHotkeys[keyCode];
                if (this.specialHandlers[label]) {
                    console.log("Got special handler");
                    return this.specialHandlers[label]();
                }
                
                if (!this.selectedFrame) return console.log("No frame");
                this.labelSelectedFrame(label);


                return true;
            }

            return;
        },
        async labelSelectedFrame(label) {
            let frame = this.frames[this.selectedFrameIndex];

            frame.labels = [ label ]
            // frame.labels.push(label);
            this.$set(this.frames, this.selectedFrameIndex, frame);
        
            upsertFrame(frame);
            console.log("Saved frame")

            this.framesAnnotatedInSession++;

            let firstFrameIndexNoLabels = this.frames.findIndex(x => !x.labels || x.labels.length == 0);
            this.selectedFrameIndex = firstFrameIndexNoLabels;
        },
        async loadMoreFrames() {
            console.log(`asking for more frames (page ${this.framesPage})`);
            // if (!this.selectedViewpoint) return console.log("No selected viewpoint watf?")
            let frames = await getFrames({
                viewpointId: this.$route.params.id,
                page: this.framesPage,
                sortBy: "frameNumber",
                sortDirection: "asc"
            });
            this.loading = false;

            this.framesPage ++;
            if (frames.data.length == 0) {
                this.noMoreFramesToLoad = true;
                return console.log("No more frames");
            }
            console.log("Got more frames")

            this.frames = this.frames.concat(frames.data);
            let firstFrameIndexNoLabels = this.frames.findIndex(x => !x.labels || x.labels.length == 0);
            this.selectedFrameIndex = firstFrameIndexNoLabels;

            if (this.getFramesRemaining() < 50) {
                console.log("Loading more frames, frames remaining is low")
                this.loadMoreFrames()
            }
        },
         getFramesRemaining() {
            let framesAnnotatedRemaining = 0;
            for (let frame of this.frames) {
                // debugger;
                if (frame.labels && frame.labels.length > 0) continue; // it has been annotated.
                framesAnnotatedRemaining++; 
            }
            console.log(`found ${framesAnnotatedRemaining} frames`)
            return framesAnnotatedRemaining;
        },
    },
    computed: {
        ...mapGetters(['selectedViewpoint']),
        selectedFrame() { return this.frames[this.selectedFrameIndex] },
        boxes() { return this.frames[this.selectedFrameIndex].boxes },
       
    },
    watch: {
        selectedFrameIndex() {
            // We need to set the bo
            if (!this.$refs.labeller) {
                console.error('No labeller ref?!!');
                return
            }
            this.$refs.labeller.setBoxes(this.selectedFrame.boxes || [])

        },
    }
}
</script>

<style>
</style>